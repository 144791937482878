<template>
  <div>
    <el-card style="margin-top: 10px">
      <el-table
          :data="list"
          style="width: 100%"
          border
          stripe
      >
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="用户ID" prop="userId">
        </el-table-column>
        <el-table-column label="手机号码" prop="phone">
        </el-table-column>
        <el-table-column label="姓名" prop="name">
        </el-table-column>
        <el-table-column label="来源" >
          <template slot-scope="{ row }">
            {{row.sourceName   }}（id:{{row.sourceId}}）
          </template>
        </el-table-column>
        <el-table-column label="反馈内容" prop="content">
        </el-table-column>
        <el-table-column label="上传图片" >
          <template slot-scope="{ row }">
            <p-image :src="row.pic" style="width: 120px;height: 120px" auto></p-image>
          </template>
        </el-table-column>

        <el-table-column label="提交时间" prop="createTime">
        </el-table-column>
<!--        <el-table-column label="操作">-->
<!--          <template slot-scope="{ row }">-->
<!--            <el-button-->
<!--                type="text"-->
<!--                icon="el-icon-edit"-->
<!--                size="mini"-->
<!--                class="skyblue"-->
<!--                @click="bannerCheck(1,row)"-->
<!--            >审核通过</el-button-->
<!--            >-->
<!--            <el-button-->
<!--                type="text"-->
<!--                icon="el-icon-edit"-->
<!--                size="mini"-->
<!--                style="color:red"-->
<!--                @click="bannerCheck(2,row)"-->
<!--            >审核不通过</el-button-->
<!--            >-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </el-card>
    <el-pagination
        :current-page="search.page"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="search.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />
    <el-dialog
        title="提示"
        :visible.sync="dialogShow"
        width="30%">
      <el-form :model="form" ref="form" label-width="120px" class="demo-ruleForm">
        <el-form-item
            label="审核失败原因"
            prop="remark"
            :rules="[
      { required: true, message: '审核失败原因不为空'}
    ]"
        >
          <el-input v-model.number="form.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogShow = false">取 消</el-button>
    <el-button type="primary" @click="subimt">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      total:0,
      search: {
        page: 1,
        pageSize: 10,
      },
      list:[],
      dialogShow:false,
      form:{
        remark:''
      }

    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get(
          '/admin/User/getFeedbackList',
          {
            params: {
              ...this.search
            }
          }
      )

      this.list = data.data.list
      this.total = data.data.totalCount

    },
    handleCurrentChange(e){
      this.search.page=e
      this.getlist()
    },
    handleSizeChange(e){
      this.search.pageSize=e
      this.getlist()
    },
    async bannerCheck(status,row){
      if(status==1){
        const { data } = await this.$http.post(
            '/admin/Enterprise/bannerCheck',
            {
              id:row.id,
              status:status,
              remark:''
            }
        )
        this.getlist()
      }else {
        this.form.id=row.id
        this.form.remark=row.remark
        this.dialogShow=true
      }
    },
    subimt() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.subPost()
          this.getlist()
          this.dialogShow=false

        }
      })

    },

    async subPost(){
      const { data } = await this.$http.post(
          '/admin/Enterprise/bannerCheck',
          {
            id:this.form.id,
            status:2,
            remark:this.form.remark
          })
      if(data.errorCode ==200){
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.getlist()
      }
    }
  }
}
</script>

<style lang="less" scoped></style>